//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ActionButtons from '@/components/Profile/Settings/ActionButtons'
export default {
    props: ['dataset'],
    components: {
        ActionButtons
    },
    data() {
        return {
            about: this.dataset.about,
            isAboutAcceptable: true
        }
    },
    methods: {
        /**
         * Before emission of save, make sure to validate.
         */
        saveFilter() {
            if (this.about.length > 2000) {
                this.isAboutAcceptable = false;
                return;
            }

            this.isAboutAcceptable = true;
            this.$emit('save', { about: this.about });
        },
        reset() {
            this.about = this.dataset.about
        }
    },
    computed: {
        // The script to determine whether to unlock the save button.
        enableSave() {
            if (this.about != this.dataset.about) {
                return false;
            }
            
            return true;
        }
    }
}
